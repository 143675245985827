import { track, updatePageProps } from '@eventbrite/datalayer-library';
import { guessUserTimezone } from '@eventbrite/datetime-fns';
import {
    GetEnrollmentStatus,
    HomepageSeasonalCarouselExperiment,
    useGetStatsigEnrollment,
} from '@eventbrite/discover-utils';
import { Icon } from '@eventbrite/eds-icon';
import { ChevronDownChunky } from '@eventbrite/eds-iconography';
import { Layout } from '@eventbrite/eds-layout';
import { gettext } from '@eventbrite/i18n';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import { requestIdleCallbackWithPolyfill } from '@eventbrite/request-idle-callback';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';
import { connect } from 'react-redux';
import { TLD_NEW_ZEALAND } from '../../../../constants/constants';
import { DateFilter } from '../../../../constants/dates';
import { IBrowseFilters } from '../../../../constants/filters';
import { NewOrleansPlaceId } from '../../../../constants/seasonalFeatured';
import { FEED_TABS, FOR_YOU } from '../../../../constants/tabConfig';
import {
    ConnectedProps,
    DiscoverState,
    SeasonalContents,
} from '../../../../types/index';
import { StickyTabs } from '../../components/Tabs';
import {
    HomeEngagementExperimentTrackingLocations,
    STATSIG_HOME_ANY_CLICK,
} from '../../constants/analytics';
import { useBrowseFilters } from '../../hooks/BrowseFilters';
import { fetchEventsForTab, fetchUserSavedEvents } from '../../redux/events/';
import { injectSeasonalTab, scrollIt } from '../../utils';
import { logToStatsigHomeEngagement } from '../../utils/analytics';
import { FeedBuilderContainer } from '../FeedBuilderContainer';
import { HomeLocationAutocompleteContainer } from '../HomeLocationAutocompleteContainer';
import { IconCategoryBrowse } from '../IconCategoryBrowse';
import { LighthouseCreatorEventsContainer } from '../LighthouseCreatorEventsContainer';
import { ReferralCreditMessage } from '../ReferralCreditMessage';
import './BrowseContainer.scss';
import { ForYouTabKey } from './ForYouTabKey';

const Chevron = (
    <Icon type={<ChevronDownChunky />} color="ui-blue--hover" size="medium" />
);

export const ONLINE_EVENTS_INPUT_COPY = gettext('Popular ');

const LOCATION_AUTOCOMPLETE_PROPS = {
    prefix: Chevron,
    borderType: 'content',
    id: 'locationPicker',
    name: 'locationPicker',
    hideOutline: true,
    isLimitedSearch: true,
    preventInputValuePrefill: true,
    shouldRemoveConflictiveAria: true,
};

const shouldIncludeCurrentTab = ({
    tabKey,
    tabsConfig,
}: {
    tabKey?: string;
    tabsConfig: { [key: string]: any };
}) => {
    if (!tabKey || !tabsConfig[tabKey]) {
        return false;
    }

    return true;
};

const getBrowseTabs = (
    dateFilter: DateFilter,
    seasonalContent?: SeasonalContents,
    locationSlug?: string,
    homepageSeasonalCarouselVariant?: boolean | string,
    isMobile?: boolean,
) => {
    const tabsConfig = injectSeasonalTab(seasonalContent);
    const tabs: {
        tabKey: string;
        value: number;
        displayName: string;
        content: JSX.Element;
    }[] = [];

    FEED_TABS.forEach((tabKey, index) => {
        const shouldAddTab = shouldIncludeCurrentTab({
            tabKey,
            tabsConfig,
        });

        if (shouldAddTab) {
            const { name, eventSearch, follow, feedConfig } =
                tabsConfig[tabKey];
            let displayName = name;

            if (tabKey === FOR_YOU) {
                displayName = <ForYouTabKey />;
            }

            tabs.push({
                tabKey,
                value: index,
                displayName: displayName,
                content: (
                    <FeedBuilderContainer
                        feedConfig={feedConfig}
                        eventSearch={eventSearch}
                        follow={follow}
                        tabKey={tabKey}
                        homepageSeasonalCarouselVariant={
                            homepageSeasonalCarouselVariant
                        }
                        dateFilter={dateFilter}
                        locationSlug={locationSlug}
                        isMobile={isMobile}
                    />
                ),
            });
        }
    });

    return tabs;
};

const LocationComponent = ({ location }: { location?: object }) => {
    const copy = gettext('Browsing events in ');

    // Homepage location pill experiment clean up. EB-229970

    return (
        <div className="browse__location eds-l-pad-top-4">
            <div className="browse__location-text">{copy}</div>
            <HomeLocationAutocompleteContainer
                autocompleteProps={LOCATION_AUTOCOMPLETE_PROPS}
                shouldDispatchSearchActions={true}
                location={location}
            />
        </div>
    );
};

const LocationComponentH1 = ({ location }: { location?: object }) => {
    const copy = gettext('Browsing events in ');

    return (
        <div className="browse__location eds-l-pad-top-4">
            <h1 className="browse__location-text">{copy}</h1>
            <HomeLocationAutocompleteContainer
                autocompleteProps={LOCATION_AUTOCOMPLETE_PROPS}
                shouldDispatchSearchActions={true}
                location={location}
            />
        </div>
    );
};

const handleLocationChange = (
    tabKey: string,
    {
        fetchEventsForTab,
        filters,
    }: {
        fetchEventsForTab: Function;
        filters?: IBrowseFilters[];
    },
) => {
    fetchEventsForTab({ tabKey, filters });
};

const handleTabLoad = (
    tabKey: string,
    {
        fetchEventsForTab,
        filters,
    }: {
        fetchEventsForTab: Function;
        filters?: IBrowseFilters[];
    },
) => {
    fetchEventsForTab({ tabKey, filters });
};

const handleTabChange = (
    tabKey: string,
    {
        fetchEventsForTab,
        filters,
    }: {
        fetchEventsForTab: Function;
        filters?: IBrowseFilters[];
    },
) => {
    scrollIt(
        ((document.querySelector('.browse-section') as HTMLElement)
            ?.offsetTop || 0) + 70,
    );

    fetchEventsForTab({ tabKey, filters });
};

const getUserTimezoneFilter = (
    weekendGuideExperimentVariant: boolean | string,
) => {
    if (weekendGuideExperimentVariant) {
        return [
            {
                name: 'timezone',
                value: guessUserTimezone(),
                payload: 'data',
            },
        ];
    }
    return undefined;
};

const handleOnClick = () => {
    logEvent(STATSIG_HOME_ANY_CLICK);
};

const Browse = (props: BrowseProps) => {
    const {
        tabKey,
        location,
        seasonalContent,
        loadingState,
        featureFlags,
        fetchEventsForTab,
        fetchUserSavedEvents,
        isAuthenticated,
        isMobile,
        tld,
    } = props;
    //TODO: Pass proper enum in for expected variant values

    const weekendGuideExperiment = useGetStatsigEnrollment<string | boolean>({
        name: 'weekend_guide_v2',
        paramName: 'weekend_guide',
        defaultValue: 'hide',
        enabled: tabKey === FOR_YOU,
    });

    const weekendGuideExperimentVariant =
        weekendGuideExperiment.status === GetEnrollmentStatus.SUCCESS
            ? weekendGuideExperiment.variant === 'show'
            : GetEnrollmentStatus.LOADING;

    const homepageSeasonalCarouselExperiment = useGetStatsigEnrollment({
        name: HomepageSeasonalCarouselExperiment.name,
        paramName: HomepageSeasonalCarouselExperiment.paramName,
        defaultValue: HomepageSeasonalCarouselExperiment.values.control,
        enabled: location?.placeId == NewOrleansPlaceId,
    });

    const homepageSeasonalCarouselVariant =
        homepageSeasonalCarouselExperiment.status ===
        GetEnrollmentStatus.SUCCESS
            ? homepageSeasonalCarouselExperiment.variant ===
              HomepageSeasonalCarouselExperiment.values.test
            : GetEnrollmentStatus.LOADING;

    const userTimezoneFilter = getUserTimezoneFilter(
        weekendGuideExperimentVariant,
    );

    const dateFilter = useBrowseFilters(fetchEventsForTab);
    const items = getBrowseTabs(
        dateFilter,
        seasonalContent,
        location.slug,
        homepageSeasonalCarouselVariant,
        isMobile,
    );
    const hasFetchedEventsRef = React.useRef(false);

    React.useEffect(() => {
        if (!hasFetchedEventsRef.current && tabKey === FOR_YOU) {
            hasFetchedEventsRef.current = true;
            requestIdleCallbackWithPolyfill(() => fetchUserSavedEvents());
        }
    }, [fetchUserSavedEvents, tabKey]);

    React.useEffect(() => {
        if (location && location.currentPlace) {
            track({
                eventName: 'HomepageSearchLocation',
                eventData: {
                    searchLocation: location.currentPlace,
                },
            });
        }
    }, [location]);

    React.useEffect(() => {
        if (location && location.currentPlace) {
            updatePageProps({
                eventName: 'SearchLocationView',
                page: {
                    searchLocation: location.currentPlace,
                },
            });
        }
    }, [location]);

    React.useEffect(() => {
        if (tabKey) {
            track({
                eventName: 'HomeTabView',
                eventData: { homeTabName: tabKey },
            });
        }
    }, [tabKey]);

    const isLoading = Boolean(loadingState?.isFeLoading);

    return (
        <div
            className="browse-section"
            id="browse-section"
            onClick={handleOnClick}
        >
            <IconCategoryBrowse isMobile={isMobile} />

            <ReferralCreditMessage />
            <hr />
            <Layout hasHorizontalGutters={true} maxWidth="large">
                {featureFlags.enableHomepageBannerCarousel &&
                tld === TLD_NEW_ZEALAND ? (
                    <LocationComponentH1 location={location} />
                ) : (
                    <LocationComponent location={location} />
                )}
            </Layout>
            <hr />
            {featureFlags.enableLighthouseCreatorsModule && (
                <Layout hasHorizontalGutters={!isMobile} maxWidth="large">
                    <LighthouseCreatorEventsContainer />
                </Layout>
            )}
            {!isLoading ? (
                <StickyTabs
                    items={items}
                    onTabChange={(tabKey: string) => {
                        const trackingLocation =
                            tabKey === FOR_YOU
                                ? HomeEngagementExperimentTrackingLocations.FOR_YOU
                                : HomeEngagementExperimentTrackingLocations.TAB;

                        logToStatsigHomeEngagement(
                            trackingLocation,
                            isAuthenticated,
                        );

                        handleTabChange(tabKey, {
                            fetchEventsForTab,
                            filters: userTimezoneFilter,
                        });
                    }}
                    onLocationChange={(tabKey: string) =>
                        handleLocationChange(tabKey, {
                            fetchEventsForTab,
                            filters: userTimezoneFilter,
                        })
                    }
                    onTabLoad={(tabKey: string) =>
                        handleTabLoad(tabKey, {
                            fetchEventsForTab,
                            filters: userTimezoneFilter,
                        })
                    }
                    initialSelectedTab={tabKey}
                    location={location}
                    dateFilter={dateFilter}
                />
            ) : (
                <Layout hasHorizontalGutters={!isMobile} maxWidth="large">
                    <LoadingSkeleton height="560px" />
                </Layout>
            )}
        </div>
    );
};

const _mapStateToProps = (state: DiscoverState) => ({
    isAuthenticated: Boolean(state.user.isAuthenticated),
    isMobile: state.env.isMobile,
    tld: state.env?.localeInfo?.tld,
    tabKey: state.content.browseState?.tabKey,
    loadingState: state.app.loadingState,
    location: state.location,
    seasonalContent: state.content.seasonal,
    featureFlags: state.app.featureFlags,
});

const _mapDispatchToProps = {
    fetchEventsForTab,
    fetchUserSavedEvents,
};

type BrowseProps = ConnectedProps<
    typeof _mapStateToProps,
    typeof _mapDispatchToProps
>;

export default connect(_mapStateToProps, _mapDispatchToProps)(Browse);
